// primary colors 
$timeless-blue: #075697;
$denim: #454F63;
$cyan: #00ABEA;
$energy-blue: #006EB6;


// secondary colors
$owl: #C0C0C0C0;
$pebble: #4D4D4D;
$momentum-green: #4AAD50;
$lime: #ACC32B;
$optimism-orange: #FF9F37;
$flame: #E1601F;
$brick: #991426;