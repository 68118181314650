// normalize elements
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  html {
    font-size: 16px;
    line-height: 1.5;
  }
  
  body {
    font-family: sans-serif;
    background-color: #fff;
    color: #333;
  }
  
  a {
    color: #000;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  h1, h2, h3, h4, h5, h6 {
    margin: 0 0 10px 0;
    font-weight: bold;
  }
  
  ul, ol {
    list-style-type: none;
    margin: 0 0 10px 0;
  }
  
  li {
    margin-bottom: 10px;
  }
  
  p {
    margin: 0 0 10px 0;
  }
  
  img {
    max-width: 100%;
    height: auto;
  }
  
  input, textarea {
    border: 1px solid #ccc;
    padding: 10px;
  }
  
  button {
    background-color: #000;
    color: #fff;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #000000;
  }
  
  